









































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { PaymentProviders, TransactionStatusEnum } from '@/types/enums'
import { Purchase } from '@/api'

@Component({
  components: {
    AppCollapse: () => import('@/components/AppCollapse.vue'),
    AppCollapseItem: () => import('@/components/AppCollapseItem.vue')
  }
})
export default class TransactionHistory extends Vue {
  isOpen = false
  tabIndex = 0
  tabs = ['Credit Cards', /* 'PaysafeCard' */]
  initialData = []
  fields = ['publicId', 'createdAt', 'updatedAt', { key: 'eCurrency', label: 'Olympus Coins' }, , 'money', 'status', 'actions']
  filteredData = null

  @Watch('tabIndex')
  async onTabIndexChange() {
    await this.setData()
  }

  get currentTransactionProvider() {
    return this.tabIndex ? PaymentProviders.MOLLIE : PaymentProviders.STRIPE
  }

  async mounted() {
    this.$root.$on('openTransactionHistoryModal', () => {
      this.isOpen = true
    })

    await this.setData()
  }

  getTransactionColor(status) {
    switch (status) {
      case TransactionStatusEnum.OPEN:
        return 'text-white'
      case TransactionStatusEnum.PENDING:
        return 'text-warning'
      case TransactionStatusEnum.PAID:
        return 'text-success'
      default:
        return 'text-danger'
    }
  }

  actionHasStatus(currentStatus) {
    return ![TransactionStatusEnum.PAID, TransactionStatusEnum.EXPIRED].includes(currentStatus)
  }

  async setData() {
    try {
      this.initialData = await Purchase.getTransactionHistory(this.currentTransactionProvider)
    } catch(e) {
      console.error('Cannot get Transaction History', e)
    }
  }

  continueTransaction(transactionUrl: string) {
    window.open(transactionUrl, '_blank')
  }

  async cancelTransaction(id: string) {
    try {
      await Purchase.cancelTransaction(this.currentTransactionProvider, id)

      await this.setData()
    } catch(e) {
      console.error(`Cannot continue Transaction ${id}`, e)
    }
  }
}
